import React from "react"
import Container from "../components/container"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"



export default () => (
    <Container>
        <Helmet>
          <title>Thank You | An Exquisite Meal</title>
          <meta name="description" content="Your message has been sent." />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        
        <div class="sub-container-page">
    
            <div class="body-content">
                <a href="/"><img src="../AnExquisiteMeal_headline.png" width="600" alt="An Exquisite Meal" /></a>
                <h2 class="headline">Thank you.</h2>
           
                <div class="description">
                    <h3>Your message has been sent.</h3>
                    <p>We'll get back to you soon.</p>
                </div>
            </div>        
        </div>
        <Footer>
        </Footer>
    </Container> 
    
)


